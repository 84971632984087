import React from "react";
import OptionsBtn from "components/OptionsButton";
import { Grid } from "@mui/material";

const HeaderSimple = ({ user, openProfileSidebar, onClick, logo, nameChat }) => {
	return (
		<header className="header_simple chat__header_simple">
			{logo && (
				<div className="chat__avatar-wrapper" onClick={openProfileSidebar}>
				<img src={logo} alt={user?.name} className="avatar_header" />
			</div>
			)}
			{nameChat && (
				<Grid container direction="column" justifyContent="center" alignItems="center">
				<span className="chat__title">Chat preview</span>
				<span className="chat__subtitle">{nameChat}</span>
				</Grid>
			)}
			{!nameChat && (
				<div className="chat__actions_simple">
				<OptionsBtn
					className="chat__action"
					ariaLabel="Menu"
					iconId="menu"
					iconClassName="chat__action-icon"
					onClick={onClick}
					options={[
						"Logout",
					]}
				/>
			</div>
			)}		
			
		</header>
	);
};

export default HeaderSimple;
