import React, { useEffect, useRef, useState } from "react";
import "./styles/main.css";
import ChatInput from "./components/ChatInput";
import Icon from "components/Icon";
import Convo from "./components/Convo";
import { useUsersContext } from "context/usersContext";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import laureate_color from "config.laureate";
import uvm_color from "config.uvm";
import unitec_color from "config.unitec";
import web_color from "config.web";
import { Link, Typography } from "@mui/material";
import HeaderSimple from "./components/HeaderSimple";
import EmojiTray from "./components/EmojiTray";

const App = styled.div`
  width: 100%;
  min-height: 100vh;
  background: var(--bg-color);
  position: relative;
  &::before {
    width: 100%;
    height: 120px;
    top: 0;
    left: 0;
    background: var(--bg-color-header);
    position: absolute;
    content: "";
    z-index: 1;
  }
  @media screen and (min-width: 1450px) {
    padding: 20px;
  }
`;

const AppContent = styled.div`
  width: 100%;
  height: 98vh;
  margin: 0 auto;
  max-width: 670px;
  position: relative;
  z-index: 100;
  display: flex;
  overflow: hidden;
  justify-content: center;
  background-color: var(--bg-color-chat);
  @media (min-width: 320px) and (max-width: 480px) {
    height: 100vh;
  }
  @media screen and (min-width: 1450px) {
    height: calc(100vh - 40px);
  }
`;

const ChatContainer = styled.div`
  display: flex;
  height: 100%;
  position: fixed;
  overflow: hidden;
  width: 100%;
`;

const ChatBody = styled.div`
  min-width: 300px;
  flex: 40%;
  border-right: 1px solid #dadada;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  align-items: center;
`;

const ChatBg = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  background-color: #ffffff;
  margin-top: 45px;
`;

const ChatContent = styled.div`
  flex: 1;
  position: relative;
  background: #e4dcd4;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 16px 16px 64px 16px;
  margin-top: 45px;
  display: flex;
  justify-content: center;
`;

const ChatScrollButton = styled.button`
  position: absolute;
  right: 15px;
  bottom: 80px;
  width: 42px;
  height: 42px;
  z-index: -1;
  border-radius: 50%;
  color: rgb(145, 145, 145);
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
`;

const ChatFooter = styled.footer`
  background: #ffffff;
  position: fixed;
  z-index: 99999;
  bottom: 16px;
  margin: 0 auto;
  left: 0px;
  right: 0px;
  max-width: 669px;
  border-top: 1px solid #dadada;

  @media (min-width: 320px) and (max-width: 480px) {
    background: #ffffff;
    position: fixed;
    z-index: 99999;
    bottom: 0px;
    margin: 0 auto;
    left: 0px;
    right: 0px;
    max-width: 669px;
  }
`;

const sleepx = (ms) => new Promise((r) => setTimeout(r, ms));

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href={props.link}>
        {props.title}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const Chat = () => {
  const { users, addNewMessage, addNewMessageServer } = useUsersContext();
  let user = users[0];
  const lastMsgRef = useRef(null);
  const [newMessage, setNewMessage] = useState("");
  const [validLogin, setvalidLogin] = useState(true);
  const [homeActivo, setHomeActivo] = useState(false);
  const [showEmojis, setShowEmojis] = useState(false);
  const [disabledInputtext, setDisabledInputtext] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [placeholder, setPlaceholder] = useState("Start typing...");
  const [property, setProperty] = useState("../../assets/images/logo.png");
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const [projectId, setProjectId] = useState();
  const [userId, setUserId] = useState();
  const [nameChat, setNameChat] = useState();

  useEffect(() => {
    const queryParameters = new URLSearchParams(location.search);
    localStorage.setItem("email", queryParameters.get("email"));
    if (queryParameters.get("id") === "uvm") {
      for (let key in uvm_color) {
        document.documentElement.style.setProperty(key, uvm_color[key]);
      }
      setProperty(uvm_color);
    } else if (queryParameters.get("id") === "unitec") {
      for (let key in unitec_color) {
        document.documentElement.style.setProperty(key, unitec_color[key]);
      }
      setProperty(unitec_color);
    } else if (queryParameters.get("id") === "laureate") {
      for (let key in laureate_color) {
        document.documentElement.style.setProperty(key, laureate_color[key]);
      }
      setProperty(laureate_color);
    } else if (queryParameters.get("id") === "web") {
      for (let key in web_color) {
        document.documentElement.style.setProperty(key, web_color[key]);
      }
      setProperty(web_color);

      if (queryParameters.get("project")) {
        setProjectId(queryParameters.get("project"));
      }

      if (queryParameters.get("user")) {
        setUserId(queryParameters.get("user"));
      }

      if(queryParameters.get("name")){
        setNameChat(queryParameters.get("name"));
      }

      setvalidLogin(true);
    } else {
      setHomeActivo(true);
    }
  }, [property, location]);

  const session = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setDisabledButton(true);
    localStorage.setItem("email", data.get("email"));
    const base64Text = btoa(data.get("email"));

    login(base64Text);
  };

  const login = (base64Text) => {
    fetch(process.env.REACT_APP_LOGIC_API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(base64Text),
    })
      .then((response) => response.json())
      .then((data) => {
        if (
          data.status === "failure" ||
          data === "" ||
          data.status === "error"
        ) {
          alert("Usuario o contraseña incorrectos");
          setvalidLogin(false);
          setDisabledButton(false);
          return;
        } else {
          localStorage.setItem("agent", data.agent);
          setvalidLogin(true);
        }
      })
      .catch((error) => {
        setvalidLogin(false);
        setDisabledButton(false);
        alert("Usuario o contraseña incorrectos");
        console.error("Error:", error);
      });
  };

  const scrollToLastMsg = () => {
    if (lastMsgRef.current) {
      lastMsgRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      console.log("lastMsgRef is null or undefined.");
    }
  };

  const submitNewMessage = async () => {
    if (!newMessage) return;

    const queryParameters = new URLSearchParams(location.search);

    setDisabledInputtext(true);
    setPlaceholder("Wait a moment...");
    let data;

    if (queryParameters.get("id") === "web") {
      data = {
        message: newMessage,
        project_id: projectId,
        user_id: userId,
      };
    } else {
      data = {
        message: newMessage,
        client_id: localStorage.getItem("email"),
        agent: queryParameters.get("type"),
      };
    }

    fetch(process.env.REACT_APP_CHAT_API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (data) => {
        for (const chunk in data.paragraphs) {
          const message = data.paragraphs[chunk];
          await addNewMessageServer(message, data.message_id);
          scrollToLastMsg();
          await sleepx(randomDelay());
        }
        // Desbloquea la entrada una vez que todas las respuestas del bot han sido procesadas.
        setDisabledInputtext(false);
        setPlaceholder("Start typing...");
      })
      .catch((error) => {
        setDisabledInputtext(false);
        console.error("Error:", error);
        setPlaceholder("Start typing...");
      });

    addNewMessage(user.id, newMessage);
    setNewMessage("");
    scrollToLastMsg();
  };

  function randomDelay() {
    return Math.random() * (1000 - 100) + 100;
    // Espera entre 0.5 y 2 segundos entre mensajes
  }

  return homeActivo ? (
    <div></div>
  ) : validLogin ? (
    // Chat component
    <App>
      <AppContent>
        <ChatContainer>
          <ChatBody>
            <ChatBg />
            <HeaderSimple
              user={user}
              onClick={() => {
                localStorage.clear();
                setvalidLogin(false);
              }}
              logo={property.logo_header}
              nameChat={nameChat}
            />
            <ChatContent>
              <Convo
                lastMsgRef={lastMsgRef}
                messages={user.messages}
                api={process.env.REACT_APP_FEEDBACK_API}
              />
            </ChatContent>
            <ChatFooter>
              <ChatScrollButton
                aria-label="scroll down"
                onClick={scrollToLastMsg}
              >
                <Icon id="downArrow" />
              </ChatScrollButton>
              <EmojiTray
                showEmojis={showEmojis}
                newMessage={newMessage}
                setNewMessage={setNewMessage}
              />
              <ChatInput
                showEmojis={showEmojis}
                setShowEmojis={setShowEmojis}
                newMessage={newMessage}
                setNewMessage={setNewMessage}
                submitNewMessage={submitNewMessage}
                disabled={disabledInputtext}
                placeholder={placeholder}
              />
            </ChatFooter>
          </ChatBody>
        </ChatContainer>
      </AppContent>
    </App>
  ) : (
    // Login component
    <App>
      <AppContent>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: "35%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={property.logo} alt="login" className="avatar" />
            <Box
              component="form"
              onSubmit={session}
              noValidLoginate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                defaultValue={queryParameters.get("email")}
              />
              {!property.hide_pass && (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
              )}

              <Button
                className="login_button"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={disabledButton}
              >
                Sign In
              </Button>
            </Box>
          </Box>
          <Copyright
            sx={{ mt: 8, mb: 4 }}
            link={property.link_footer}
            title={property.title}
          />
        </Container>
      </AppContent>
    </App>
  );
};

export default Chat;
