const web_color = {
    '--bg-color-header': '#55BBFF',
    '--bg-color': '#ededed',
    '--bg-color-chat': '#ededed',
    '--bg-color-botton': '#55BBFF',
    '--bg-color-botton-hover': '#55BBFF',
    '--chat-encryption-msg': '#e7e9f9',
    '--chat-msg-sent': '#55BBFF',
    '--chat-msg-group': '#55BBFF',
    '--chat-msg-rxd': '#ededed',
    '--chat-msg-group-rxd': '#ededed',
    logo: '/images/logo_white.png',
    hide_pass: true,
    link_footer: 'https://www.google.cl',
    title: 'Gen AI',
};

export default web_color;