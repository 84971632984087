import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Chat from "pages/Chat";

function App() {
	return (
		<Router>
			<Switch>
				<Route path="/" component={Chat} />
				<Route path="/:id/:type" component={Chat} />
			</Switch>
		</Router>
	);
}

export default App;
