import Icon from "components/Icon";
import React, { useState } from "react";
import formatTime from "utils/formatTime";
import { nanoid } from 'nanoid'
import ThumbUpAltOutlined from '@material-ui/icons/ThumbUpAltOutlined';
import ThumbDownAltOutlined from '@material-ui/icons/ThumbDownAltOutlined';
import Close from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import styled from "styled-components";
import Button from '@mui/material/Button';

const Element = styled.div`
    display: flex;
    flex-direction: row;
`;

const HoverIconUp = styled(ThumbUpAltOutlined)`
  transition: transform 0.3s ease-in-out;
  font-size: 16px !important;
  cursor: pointer;
  color: #b5b5b5;

  &:hover {
    transform: scale(1.2);
  }
`;

const HoverIconUpFill = styled(ThumbUpAltOutlined)`
  transition: transform 0.3s ease-in-out;
  color: #67624a;
  width: 20px;
`;

const HoverIconDown = styled(ThumbDownAltOutlined)`
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  font-size: 16px !important;
  color: #b5b5b5;

  &:hover {
    transform: scale(1.2);
  }
`;

const HoverIconDownFill = styled(ThumbDownAltOutlined)`
  transition: transform 0.3s ease-in-out;
  color: #67624a;
  cursor: pointer;
`;


const ModalContainer = styled(Modal)`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
`;

const ModalContent = styled.div`
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const ModalTitle = styled.h2`
  font-size: 18px;
`;

const ModalInput = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const TitleWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${props => props.color || '#cbffd6'};
`;

const Convo = ({ lastMsgRef, messages: allMessages, api }) => {
	const dates = Object.keys(allMessages);
	let encryptionShown = false;
	const [isOpen, setIsOpen] = useState(false);
	const [modalOption, setModalOption] = useState(
		{
			title: 'Proporcionar comentarios adicionales',
			placeholder: '¿Qué te gusta de la respuesta?',
			like: false
		}
	);
	const [id, setId] = useState(1);
	const [like, setLike] = useState(false);

	const openModal = () => {
		setIsOpen(true);
	};

	const closeModal = () => {
		setIsOpen(false);
	};

	const handleClose = () => {
		closeModal();
	};

	const handleClickUp = (message) => {
		setId(message.id)
		setLike(true);
		setModalOption({ ...modalOption, title: 'Proporcionar comentarios adicionales', placeholder: '¿Qué te gusta de la respuesta?', like: true})
		openModal();
	};

	const handleClickDown = (message) => {
		setId(message.id)
		setLike(false);
		setModalOption({ ...modalOption, title: 'Proporcionar comentarios adicionales', placeholder: '¿Cuál fue el problema con la respuesta? ¿Cómo podría mejorarse?', like: false })
		openModal();
	};

	const feedback = (event) => {
		console.log(event.target.feedback.value);
		event.preventDefault();
		closeModal();

		fetch(api, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ like: like, id: id, feedback: event.target.feedback.value })
		})
			.then(response => response.json())
			.then(data => {
				console.log('Success:', data);
			})
			.catch(error => {
				console.error('Error:', error);
			});
	};

	return dates.map((date, dateIndex) => {
		const messages = allMessages[date];
		return (
			<div key={nanoid()} id="chatbox">
				<div className="chat__date-wrapper">
					<span className="chat__date"> {date}</span>
				</div>
				{dateIndex === 0 && (
					<p className="chat__encryption-msg" onClick={openModal}>
						<Icon id="lock" className="chat__encryption-icon" />
						Messages are end-to-end encrypted. The conversations will be saved to help improve the interaction.
					</p>
				)}
				<div className="chat__msg-group">
					{messages.map((message, msgIndex) => {
						const assignRef = () =>
							dateIndex === dates.length - 1 && msgIndex === messages.length - 1
								? lastMsgRef
								: undefined;
						return (
							<>
								{message.image ? (
									<div
										className={`chat__msg chat__img-wrapper ${message.sender ? "chat__msg--rxd" : "chat__msg--sent"
											}`}
										ref={assignRef()}
									>
										<span className="chat__msg-footer">
											<span >{formatTime(message.time)}</span>
										</span>
									</div>
								) : message.sender ? (
									<Element>
										<p className="chat__msg_server chat__msg--rxd" ref={assignRef()}>
											<span>{message.content}</span>
											<span className="chat__msg-filler"> </span>
											<span className="chat__msg-footer">
												<span>{formatTime(message.time)}</span>
											</span>
										</p>
										{!encryptionShown &&
											<React.Fragment>
												<HoverIconUp className="chat__encryption-icon" onClick={() => handleClickUp(message)} />
												<HoverIconDown className="chat__encryption-icon" onClick={() =>handleClickDown(message)} />
											</React.Fragment>
										}
										{(encryptionShown = true)}
									</Element>
								) : (
									<p className="chat__msg chat__msg--sent" ref={assignRef()}>
										<span>{message.content}</span>
										<span className="chat__msg-filler"> </span>
										<span className="chat__msg-footer">
											<span style={{ color: '#fff' }}> {formatTime(message.time)} </span>
										</span>
										{(encryptionShown = false)}
									</p>
								)}
							</>
						);
					})}
				</div>
				<ModalContainer open={isOpen} onClose={handleClose}>
					<ModalContent>
						<form onSubmit={feedback} className="feedback">
							<TitleWrapper>
								{modalOption.like ? <Circle color="#cbffd6"><HoverIconUpFill /></Circle> : <Circle color="#ffd7d7"><HoverIconDownFill /></Circle>}
								<ModalTitle>{modalOption.title}</ModalTitle>
								<CloseButton onClick={closeModal}>
									<Close className="chat__encryption-icon" onClick={handleClickDown} />
								</CloseButton>
							</TitleWrapper>
							<ModalInput
								type="text"
								name="feedback"
								placeholder={modalOption.placeholder}
							/>
							<Button
								className="login_button"
								type="submit"
								variant="contained"
							>
								Enviar comentarios
							</Button>
						</form>
					</ModalContent>
				</ModalContainer>
			</div>
		);
	});
};

export default Convo;
