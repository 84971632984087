const users = [
	{
		id: 1,
		profile_picture: '',
		name: "Laureate",
		phone_number: "+2348123456789",
		whatsapp_name: "Beyonce",
		unread: 0,
		messages: {
			TODAY: [

			],
		},
		group: false,
		pinned: true,
		typing: false,
	}
];

export default users;
