const laureate_color = {
  '--bg-color-header': 'rgb(241 92 34)',
  '--bg-color': '#ededed',
  '--bg-color-chat': '#ededed',
  '--bg-color-botton': 'rgb(241 92 34)',
  '--bg-color-botton-hover': '#f18d65',
  '--chat-encryption-msg': '#f15c2236',
  '--chat-msg-sent': '#f15c22',
  '--chat-msg-group': '#f15c22',
  '--chat-msg-rxd': '#ededed',
  '--chat-msg-group-rxd': '#ededed',
  logo: '/images/logo_laureate.png',
  hide_pass: true,
  logo_header: '/images/logo_h.png',
  link_footer: 'https://www.laureate.net/mexico/',
  title: 'LAUREATE',
};

export default laureate_color;