const unitec_color = {
  '--bg-color-header': '#0d6fb8',
  '--bg-color': '#ededed',
  '--bg-color-chat': '#ededed',
  '--bg-color-botton': '#0d6fb8',
  '--bg-color-botton-hover': '#3890d3',
  '--chat-encryption-msg': '#E1F2FA',
  '--chat-msg-sent': '#0d6fb8',
  '--chat-msg-group': '#0d6fb8',
  '--chat-msg-rxd': '#ededed',
  '--chat-msg-group-rxd': '#ededed',
  logo: '/images/logo_unitec.png',
  hide_pass: true,
  logo_header: '/images/logo_unitec.png',
  link_footer: 'https://www.unitec.mx/politicas-de-privacidad/',
  title: 'UNITEC',
};

export default unitec_color;