import React from "react";
// import Icon from "components/Icon";

// const emojiTabs = [
// 	{ icon: "emojiPeople", label: "People emojis", active: false }
// ];

const emojis = [
	'😶', '😃', '😄', '😆', '😄', '😁', '🙂', '🙃', '🙁', '☹️', '😕', '😐', '😑', '😯', 
	'😲', '😮', '😮', '😧', '😞', '😠', '😡', '😌', '😒', '😏', '😉', '😘', '😍', '😗', '😙', 
	'😚', '😔', '😖', '☺️', '😊', '🤗', '😵', '😮', '😩', '😝', '😛', '😜', '😋', '🙄', 
	'😳', '😫', '😇', '😬', '😅', '😓', '😓', '😥', '😰', '😨', '😱', '😂', '😪', '😎', '😷', 
	'😴', '😭', '🤕', '🤑', '🤔', '🤒', '🤐', '🤓'
  ];

const EmojiTray = ({ showEmojis, newMessage, setNewMessage }) => {
	const addEmoji = (emoji) => {
		setNewMessage(newMessage + emoji);
	};
	

	return (
		<div
			className={`emojis__wrapper ${
				showEmojis ? "emojis__wrapper--active" : ""
			}`}
		>
			{/* <div className="emojis__tabs">
				{emojiTabs.map((tab) => (
					<div
						className={`emojis__tab ${tab.active ? "emojis__tab--active" : ""}`}
						key={tab.label}
					>
						<button aria-label={tab.label} key={tab.icon}>
							<Icon id={tab.icon} className="emojis__tab-icon" />
						</button>
					</div>
				))}
			</div> */}
			<div className="emojis__content">
				<div className="emojis__grid">
					{new Array(6).fill(null).map((_, rowIndex) =>
						new Array(11).fill(null).map((_, colIndex) => (
							<div
								role="img"
								aria-label="emoji"
								onClick={() => addEmoji(emojis[rowIndex * 11 + colIndex])}
								key={`${rowIndex}-${colIndex}`}
								className="emoji emojis__emoji"
								style={{
									backgroundPositionX: -3 - 44.2 * colIndex,
									backgroundPositionY: -6 - 52 * rowIndex,
								}}
							></div>
						))
					)}
				</div>
			</div>
		</div>
	);
};

export default EmojiTray;
