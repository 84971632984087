import React from "react";
import Icon from "components/Icon";

const ChatInput = ({
	newMessage,
	setNewMessage,
	submitNewMessage,
	showEmojis,
	setShowEmojis,
	disabled,
	placeholder
}) => {
	const detectEnterPress = (e) => {
		if (e.key === "Enter" || e.keyCode === 13) {
			submitNewMessage();
		}
	};
	return (
		<div className="chat__input-wrapper">
						{showEmojis && (
				<button aria-label="Close emojis" onClick={() => setShowEmojis(false)}>
					<Icon id="cancel" className="chat__input-icon" />
				</button>
			)}
			<button aria-label="Emojis" onClick={() => setShowEmojis(true)}>
				<Icon
					id="smiley"
					className={`chat__input-icon ${
						showEmojis ? "chat__input-icon--highlight" : ""
					}`}
				/>
			</button>

			<input
				className="chat__input"
				placeholder={placeholder}
				autoComplete="off" 
				autoCorrect="off" 
				autoCapitalize="off" 
				type="text"
				autoFocus
				value={newMessage}
				onChange={(e) => setNewMessage(e.target.value)}
				onKeyDown={detectEnterPress}
				disabled={disabled}
			/>
			{newMessage ? (
				<button aria-label="Send message" onClick={submitNewMessage}>
					<Icon id="send" className="chat__input-icon" />
				</button>
			) : (
				<button aria-label="Send message">
					<Icon id="send" className="chat__input-icon" />
				</button>
			)}
		</div>
	);
};

export default ChatInput;
