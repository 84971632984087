const uvm_color = {
    '--bg-color-header': '#d32f2f',
    '--bg-color': '#ededed',
    '--bg-color-chat': '#ededed',
    '--bg-color-botton': '#d9272e',
    '--bg-color-botton-hover': '#df4f54',
    '--chat-encryption-msg': '#ffcfcf',
    '--chat-msg-sent': '#d9272e',
    '--chat-msg-group': '#d9272e',
    '--chat-msg-rxd': '#ededed',
    '--chat-msg-group-rxd': '#ededed',
    logo: '/images/logo_uvm.png',
    hide_pass: true,
    logo_header: '/images/logo_uvm_h.png',
    link_footer: 'https://uvm.mx/aviso-de-privacidad',
    title: 'UVM',
};

export default uvm_color;