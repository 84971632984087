import { createContext, useContext, useState } from "react";
import contacts from "data/contacts";

const UsersContext = createContext();

const useUsersContext = () => useContext(UsersContext);

const UsersProvider = ({ children }) => {

	const [users, setUsers] = useState(contacts);

	const _updateUserProp = (userId, prop, value) => {
		setUsers((users) => {
			const usersCopy = [...users];
			let userIndex = users[0];
			const userObject = usersCopy[userIndex];
			usersCopy[userIndex] = { ...userObject, [prop]: value };
			return usersCopy;
		});
	};


	const setUserAsUnread = () => {
		_updateUserProp("unread", 0);
	};

	const addNewMessage = (userId, message) => {
		let userIndex = users.findIndex((user) => user.id === userId);
		const usersCopy = [...users];
		const newMsgObject = {
			content: message,
			sender: null,
			time: new Date().toLocaleTimeString(),
			status: "received",
		};

		usersCopy[userIndex].messages.TODAY.push(newMsgObject);
		setUsers(usersCopy);

		//socket.emit("fetch_response", { userId });
		saveData('data',usersCopy)
	};

	const addNewMessageServer = (message, id) => {
		const usersCopy = [...users];
		const newMsgObject = {
			content: message,
			sender: 1,
			id: id,
			time: new Date().toLocaleTimeString(),
			status: null,
		};

		usersCopy[0].messages.TODAY.push(newMsgObject);
		setUsers(usersCopy);

		//socket.emit("fetch_response", { userId });
		saveData('data',usersCopy)
	};

	function saveData(key, value) {
		localStorage.setItem(key, JSON.stringify(value));
	}

	return (
		<UsersContext.Provider value={{ users, setUserAsUnread, addNewMessage, addNewMessageServer }}>
			{children}
		</UsersContext.Provider>
	);
};

export { useUsersContext, UsersProvider };
